import React from 'react'
import Layout from '../../components/layout'
import { ReadingComprehensionCreator } from '../../components/ChallengeCreators'

const ReadingComprehensionCreatorPage = ({ challengeId }) => (
  <Layout>
    <ReadingComprehensionCreator challengeId={challengeId} />
  </Layout>
)

export default ReadingComprehensionCreatorPage
